import { ParsedProduct } from '@hultafors/shared/types';

import { SnickersProduct } from '@hultafors/snickers/types';

import { EmptyProducts } from '../EmptyProducts/EmptyProducts';
import { Product } from '../Product/Product';

export interface ProductsProps {
  products?: ParsedProduct[];
  badgeText: string;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  isLoading?: boolean;
  numberOfItems?: number;
  categorySlug?: string;
}

export const Products: React.FC<ProductsProps> = ({
  products = [],
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  isLoading,
  numberOfItems = 12,
  categorySlug,
}) => {
  const productMapper = (product: ParsedProduct) => (
    <Product
      key={`p-${product.productId}`}
      product={product as SnickersProduct}
      badgeText={badgeText}
      rrpLabel={rrpLabel}
      rrpIncludingVatLabel={rrpIncludingVatLabel}
      categorySlug={categorySlug}
    />
  );
  return isLoading ? (
    <EmptyProducts pageSize={numberOfItems} />
  ) : (
    <>{products?.map(productMapper)}</>
  );
};
