import dynamic from 'next/dynamic';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/snickers/hooks';

import LogoSvg from './logo-small.svg';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

const FooterOld = dynamic(() =>
  import('../FooterOld/FooterOld').then((mod) => mod.FooterOld),
);

interface FooterMenuLink {
  title?: string;
  url?: string;
}
interface FooterMenuItem {
  heading?: string;
  links?: FooterMenuLink[];
  includeCookieToggle?: boolean;
}

export const Footer = () => {
  const { footer, global } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();

  if (!footer.useNewFooter) {
    return <FooterOld />;
  }

  return (
    <FooterStyled
      activeMarket={activeMarket}
      brand="snickers"
      brandLinks={footer?.hultaforsBrands}
      bottomIntro={footer?.bottomIntro ?? ''}
      introText={footer.introText ?? ''}
      items={footer?.menuItems as FooterMenuItem[]}
      logoAriaLabel="Snickers Workwear"
      logoIcon={
        <LogoSvg aria-hidden={true} focusable={false} width={48} height={48} />
      }
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={global?.changeLanguage ?? ''}
      socialMediaLabel={footer?.socialMediaLabel}
      socialMediaMenu={footer?.socialMediaMenu}
      toggleMarketPicker={toggle}
      cookieSettingsLabel={footer?.cookieSettings}
    />
  );
};
